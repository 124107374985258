import { FormEvent,  useState } from 'react';
import axios from 'axios';

export function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={(e) => handleLogin(e, username, password, setError)}>
        <table>
          <tr>
            <td><label htmlFor="username">Username</label></td>
            <td>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </td>
          </tr>
          <tr>
            <td>          <label htmlFor="password">Password</label>
            </td>
            <td>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </td>
          </tr>
        </table>
        <button type="submit">Login</button>
      </form>
      {error && <p className="error" style={{"color": "red"}}>{error}</p>}
    </div>
  );
}

async function handleLogin(e: FormEvent, username: string, password: string, setError: (error: string) => void) {
  e.preventDefault();
  try {
    const response = await axios.post('/api/auth', {
      username,
      password,
    });
    if (response.status === 200) {
      window.location.href = "/";
    }    
  } catch (error) {
    setError('Login failed. Please check your username and password and try again.');
  }
}